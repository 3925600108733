import {fetchToken} from "../Auth";
import axios from "axios";
import {getUrl} from "../Config";

class DatabaseUtils {

    static updateData = async (endpoint, data) => {

        let url = getUrl() + endpoint;

        let token = fetchToken();
        let options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*',
            }
        };

        try {
            const response = await axios.put(url, data, options)
            if (response.status === 200) {
                return true;
            } else {
                return response;
            }
        } catch (error) {
            console.error(error);
            // throw new Error(error);
        }
    }

}

export default DatabaseUtils;