import React from 'react';

class InputEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputid: props.id,
            label: props.label,
            val: props.value,
            error: props.error,
        };
    }

    handleChangeEmail = (event) => {
        const email = event.target.value;
        this.props.onChange(this.props.id, email);
    };

    getClasses = () => {
        let classes = 'block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4';
        if (true === this.props.show) {
            if (false !== this.props.error && '' === this.props.value) {
                classes = 'block text-red-500 font-bold md:text-right mb-1 md:mb-0 pr-4';
            } else if (false !== this.props.error && false === this.props.valid) {
                classes = 'block text-red-500 font-bold md:text-right mb-1 md:mb-0 pr-4';
            }
        }
        return classes;
    };

    getDisabled = () => {
        let disabled = '';
        if (true === this.props.disabled) {
            disabled = 'disabled';
        }
        return disabled;
    };

    render() {
        return (
            <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/4">
                    <label className={this.getClasses()} htmlFor="inline-full-name">
                        {this.state.label} {true === this.props.show ? <span>&lowast;</span> : ''}
                        {false !== this.props.error && false === this.props.valid ? (
                            <span className="text-red-500 text-sm w-full flex justify-end">
                                Bitte gib eine gültige Email Adresse ein.
                            </span>
                        ) : (
                            ''
                        )}
                    </label>
                </div>
                <div className="md:w-3/4">
                    <input
                        className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                        id={this.state.inputid}
                        type="email"
                        value={this.props.value}
                        onChange={this.handleChangeEmail}
                        disabled={this.getDisabled()}
                    />
                </div>
            </div>
        );
    }
}

export default InputEmail;
