import React, { useEffect } from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';

// @ts-ignore
import { validate as isValidUUID } from 'uuid';
import CrudUtils from '../CRUD/CrudUtils';
import { useNavigate } from 'react-router';

const BackendArticleCreate = () => {
    const navigate = useNavigate();
    const docNr = CrudUtils.createDocumentNumber('ART');

    useEffect(() => {
        try {
            const articleData = {
                article_number: docNr,
            };

            // const timer = setTimeout(() => {
            //     CrudUtils.postData(offerData, 'article/new').then(() => {
            //         navigate('/backend/article/edit/' + docNr);
            //     });
            // }, 1000);
            // return () => clearTimeout(timer);

            const timer = setTimeout(() => {
                CrudUtils.postData(articleData, 'article/new').then(() => {
                    navigate('/backend/article/edit/' + docNr);
                });
            }, 1000);
            return () => clearTimeout(timer);

            // CrudUtils.postData(articleData, 'article/new').then(() => {
            //     const timer = setTimeout(() => {
            //         navigate('/backend/article/edit/' + docNr);
            //     }, 1000);
            //     return () => clearTimeout(timer);
            // });
        } catch (error) {
            console.error(error);
        }
    }, []);

    // @ts-ignore
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-between text-white bg-[#3f888f]"
                >
                    <div className="w-full">
                        <h3 className="text-gray-200 ml-5">Plesae wait while we create the article</h3>
                    </div>
                </section>

                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendArticleCreate;
