import React from 'react';
import moment from 'moment';

const TableRowOffer = (props: {
    id: string;
    key: string;
    col_number: string;
    col_date: string;
    col_description: string;
    col_internal_note: string;
}) => {
    let offerDate = moment(props.col_date).format('DD.MM.YYYY');

    return (
        <tr key={props.id}>
            <td className="p-2 border border-gray-200 w-1/5">{offerDate}</td>
            <td className="p-2 border border-gray-200 w-1/5">{props.col_number}</td>
            <td className="p-2 border border-gray-200 w-1/5">{props.col_description}</td>
            <td className="p-2 border border-gray-200 w-1/5">{props.col_internal_note}</td>
            <td className="p-2 border border-gray-200 w-full flex flex-row">
                <a href={'/backend/offer/edit/' + props.col_number} className="text-xs">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                </a>
                <span className="pr-1">&nbsp;</span>
                <a href={'/backend/offer/preview/' + props.col_number} className="text-xs">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                </a>
            </td>
        </tr>
    );
};

export default TableRowOffer;
