import React from 'react';
// @ts-ignore
import Quality2Img from '../../Website/ImagesFiles/quality_2.png';
// @ts-ignore
import Service1Img from '../../Website/ImagesFiles/service_1.png';
// @ts-ignore
import Design1Img from '../../Website/ImagesFiles/design_1.png';
import FrontendHeader from '../../Website/Frontend/FrontendHeader';
import FrontendFooter from '../../Website/Frontend/FrontendFooter';
// @ts-ignore
import UhrenImg from '../../Website/ImagesFiles/uhren.png';
// @ts-ignore
import UntersetzerImg from '../../Website/ImagesFiles/untersetzer.png';
// @ts-ignore
import SpiegelBoxImg from '../../Website/ImagesFiles/spiegelbox.png';
// @ts-ignore
import SpiegelRahmenImg from '../../Website/ImagesFiles/spiegel_rahmen.png';

const FrontendLaser = () => {
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white">
                {<FrontendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-64 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="text-center">
                        <h1 className="text-4xl font-bold">Lasergravur und Laserschnitt</h1>
                        <p className="mt-2 text-xl">Präzision trifft Kreativität.</p>
                    </div>
                </section>

                <section className="p-8 mt-14">
                    <section>
                        <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-2 sm:gap-4 pb-5">
                            <div className="w-full p-2 justify-center flex">
                                <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                                    <h2 className="text-2xl font-bold mb-4 text-gray-800">
                                        Gravieren mit einem 10-Watt-Laser
                                    </h2>
                                    <p className="text-gray-700 mb-4">
                                        Gravieren mit einem 10-Watt-Laser ist eine präzise und vielseitige Methode, um
                                        unterschiedliche Materialien dauerhaft zu kennzeichnen oder zu dekorieren. Dank
                                        der feinen und intensiven Laserstrahlen lassen sich selbst detaillierte Designs
                                        oder Schriftzüge in verschiedenen Oberflächen gravieren.
                                    </p>
                                    <h3 className="text-xl font-semibold mb-3 text-gray-800">
                                        Materialien für das Gravieren
                                    </h3>
                                    <p className="text-gray-700 mb-4">
                                        Ein 10-Watt-Laser kann eine Vielzahl von Materialien gravieren, darunter:
                                    </p>
                                    <ul className="list-disc list-inside mb-4 text-gray-700">
                                        <li>
                                            <strong>Holz</strong>: Ideal für dekorative Gravuren oder personalisierte
                                            Geschenke.
                                        </li>
                                        <li>
                                            <strong>Acryl</strong>: Ermöglicht klare, präzise Schnitte und Gravuren.
                                        </li>
                                        <li>
                                            <strong>Leder</strong>: Eignet sich für Branding oder individuelle Designs
                                            auf Lederwaren.
                                        </li>
                                        <li>
                                            <strong>Glas</strong>: Gravuren auf Glas können feine und detaillierte
                                            Muster erzeugen.
                                        </li>
                                        <li>
                                            <strong>Metall</strong>: Bei bestimmten Metallen kann ein 10-Watt-Laser
                                            Gravuren erzeugen.
                                        </li>
                                    </ul>

                                    <h3 className="text-xl font-semibold mb-3 text-gray-800">
                                        Anwendungsmöglichkeiten
                                    </h3>
                                    <ul className="list-disc list-inside mb-4 text-gray-700">
                                        <li>
                                            <strong>Personalisierte Geschenke</strong>: Gravuren auf Holz, Glas oder
                                            Leder.
                                        </li>
                                        <li>
                                            <strong>Industrielle Kennzeichnungen</strong>: Seriennummern, Barcodes auf
                                            Acryl oder Metall.
                                        </li>
                                        <li>
                                            <strong>Kunst und Handwerk</strong>: Detaillierte Designs für kreative
                                            Projekte.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="w-full p-2 justify-center flex">
                                <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                                    <h2 className="text-2xl font-bold mb-4 text-gray-800">
                                        Schneiden mit einem 10-Watt-Laser
                                    </h2>
                                    <p className="text-gray-700 mb-4">
                                        Das Schneiden mit einem 10-Watt-Laser ist besonders für dünnere Materialien und
                                        präzise Schnitte geeignet, ideal für Hobbyprojekte und den Modellbau.
                                    </p>
                                    <h3 className="text-xl font-semibold mb-3 text-gray-800">Geeignete Materialien</h3>
                                    <p className="text-gray-700 mb-4">
                                        Ein 10-Watt-Laser kann eine Vielzahl von nicht-metallischen Materialien
                                        schneiden:
                                    </p>
                                    <ul className="list-disc list-inside mb-4 text-gray-700">
                                        <li>
                                            <strong>Holz</strong>: Dünne Sperrholzplatten oder Weichholz für Modellbau
                                            oder Dekorationsprojekte.
                                        </li>
                                        <li>
                                            <strong>Acryl</strong>: Dünnes Acryl (2-3 mm) kann sauber geschnitten
                                            werden.
                                        </li>
                                        <li>
                                            <strong>Pappe und Karton</strong>: Ideal für Schablonen und Bastelprojekte.
                                        </li>
                                        <li>
                                            <strong>Stoffe und Leder</strong>: Dünne Stoffe und Leder können präzise
                                            geschnitten werden.
                                        </li>
                                        <li>
                                            <strong>Schaumstoff</strong>: Gut für Verpackungseinlagen und Modellbau
                                            geeignet.
                                        </li>
                                    </ul>

                                    <h3 className="text-xl font-semibold mb-3 text-gray-800">
                                        Anwendungsmöglichkeiten
                                    </h3>
                                    <ul className="list-disc list-inside mb-4 text-gray-700">
                                        <li>
                                            <strong>Modellbau</strong>: Präzise Bauteile aus Holz, Acryl oder Karton.
                                        </li>
                                        <li>
                                            <strong>Dekoration</strong>: Herstellung von personalisierten
                                            Dekorationsobjekten wie Untersetzern oder Wandbildern.
                                        </li>
                                        <li>
                                            <strong>Textil- und Lederverarbeitung</strong>: Lasergeschnittene Muster und
                                            Formen in Stoffen oder Leder.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section className="py-12">
                    <div className="container mx-auto px-4">
                        <div className="container px-4 p-10">
                            <div className="text-center mb-20">
                                <h1 className="text-3xl font-bold">
                                    Entdecke die Präzision und Vielfalt der Lasergravur und Laserschnitt!
                                </h1>
                                <p className="mt-4">
                                    Willkommen in der Welt der Lasergravur und Laserschnitt – der perfekten Lösung für
                                    kreative Köpfe und anspruchsvolle Kunden. Unsere hochmoderne Lasertechnologie
                                    ermöglicht es, Materialien wie Holz, Acryl, Glas, Metall und viele mehr mit höchster
                                    Präzision zu schneiden und zu gravieren.
                                </p>
                            </div>

                            <div className="mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8 ">
                                <div className="mb-8 border-2 rounded-xl p-5 shadow-xl">
                                    <img
                                        className="mx-auto mb-4 max-h-48 object-cover"
                                        src={Quality2Img}
                                        alt="Individuelles Design"
                                    />
                                    <h2 className="text-xl font-semibold my-8">Warum Lasergravur und Laserschnitt?</h2>
                                    <ul className="list-disc list-inside">
                                        <li className="mb-4">
                                            <strong>Hochpräzise:</strong>
                                            <p className="mt-1 ml-5">
                                                Unsere Lasertechnologie gewährleistet feinste Details und exakte
                                                Schnitte.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <strong>Vielseitig:</strong>
                                            <p className="mt-1 ml-5">
                                                Ob personalisierte Geschenke, dekorative Elemente – die
                                                Einsatzmöglichkeiten sind vielfältig.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <strong>Kreativ und Individuell:</strong>
                                            <p className="mt-1 ml-5">
                                                Jedes Projekt wird nach Deinen Vorstellungen und Wünschen gestaltet. Wir
                                                setzen Deine Ideen um.
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mb-8 border-2 rounded-xl p-5 shadow-xl">
                                    <img
                                        className="mx-auto mb-4 max-h-48 object-cover"
                                        src={Service1Img}
                                        alt="Hohe Qualität"
                                    />
                                    <h2 className="text-xl font-semibold my-8">Unsere Dienstleistungen</h2>
                                    <ul className="list-disc list-inside">
                                        <li className="mb-4">
                                            <strong>Individuelle Gravuren:</strong>{' '}
                                            <p className="mt-1 ml-5">
                                                Persönliche Geschenke – lass Dich von der Vielfalt unserer
                                                Gravurmöglichkeiten begeistern.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <strong>Fertigung von Gegenständen:</strong>{' '}
                                            <p className="mt-1 ml-5">
                                                Ideal für personalisierte Geschenke und Dekorationsartikel.
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mb-8 border-2 rounded-xl p-5 shadow-xl">
                                    <img
                                        className="mx-auto mb-4 max-h-48 object-cover"
                                        src={Design1Img}
                                        alt="Individuelles Design"
                                    />
                                    <h2 className="text-xl font-semibold my-8">Perfekt für jede Gelegenheit</h2>
                                    <ul className="list-disc list-inside">
                                        <li className="mb-4">
                                            <strong>Personalisierte Geschenke:</strong>{' '}
                                            <p className="mt-1 ml-5">Schenke etwas Einzigartiges und Persönliches.</p>
                                        </li>
                                        <li className="mb-4">
                                            <strong>Hochzeits- und Eventdekorationen:</strong>{' '}
                                            <p className="mt-1 ml-5">
                                                Gestalte unvergessliche Momente mit individuell angefertigten
                                                Dekorationen.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <strong>Unternehmenslösungen:</strong>{' '}
                                            <p className="mt-1 ml-5">
                                                Professionelle Beschilderungen, Werbematerialien und maßgeschneiderte
                                                Firmenpräsente.
                                            </p>
                                        </li>
                                        <li className="mb-4">
                                            <strong>Künstlerische Projekte:</strong>{' '}
                                            <p className="mt-1 ml-5">Erwecke Deine kreativen Visionen zum Leben.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="text-center mt-8 p-2">
                                <p>
                                    Verpasse nicht die Gelegenheit, Deine Designs in höchster Qualität zu präsentieren.
                                    Kontaktiere uns noch heute und lass Dich von uns beraten. Mit unserem Flexdruck wird
                                    Dein Design zum Hingucker!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container mx-auto p-6 ">
                        <div className="mb-10 border-2 rounded-xl p-5">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">Unseren aktuellen Angebote</h2>
                            <p className="text-gray-700 mb-10">
                                Entdecke unsere neuesten Angebote in der Flexdruck- und Lasergravur-Fertigung!
                            </p>

                            <section>
                                <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-4 sm:gap-4 pb-5">
                                    <div className="w-full p-2 rounded shadow-xl">
                                        <a
                                            href="https://lagockirefinement.etsy.com/de/listing/1766287903/uhr-aus-schiefer"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                        >
                                            <img src={UhrenImg} alt="Uhr" className="h-72" />
                                        </a>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-xl">
                                        <a
                                            href="https://lagockirefinement.etsy.com/de/listing/1752101000/gravierte-untersetzer-aus-schiefer"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                        >
                                            <img src={UntersetzerImg} alt="Untersetzer" className="h-56 mt-7" />
                                        </a>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-xl">
                                        <a
                                            href="https://lagockirefinement.etsy.com/de/listing/1766308881/handgemachte-spiegelbox-mit"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                        >
                                            <img src={SpiegelBoxImg} alt="Spiegelbox" className="h-56 mt-7" />
                                        </a>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-xl">
                                        <a
                                            href="https://lagockirefinement.etsy.com/de/listing/1756796536/gravierter-spiegel-mit-rahmen-und"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-center"
                                        >
                                            <img
                                                src={SpiegelRahmenImg}
                                                alt="Spiegel mit Rahmen"
                                                className="h-56 mt-7"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>

                {<FrontendFooter />}
            </div>
        </main>
    );
};

export default FrontendLaser;
