// @ts-ignore
import React from 'react';
import FormUtils from '../../../Website/Form/FormUtils';
import CrudUtils from './CrudUtils';

class SetDocumentPositions {
    static SetPositions = async (data: any, url: string) => {
        const fields = [data.amount, data.article, data.price];

        if (!FormUtils.isValidateFormData(fields)) {
            return ['Die Position konnte nicht gespeichert werden. Bitte alle Felder ausfüllen.', 'danger'];
        }

        const positionData = {
            amount: parseInt(data.amount),
            article: data.article,
            price: data.price.replace(',', '.'),
            offer_id: data.offer_id,
        };

        try {
            await CrudUtils.postData(positionData, url);
            return ['Angebotsposition gespeichert', 'info'];
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };
}

export default SetDocumentPositions;
