import React from 'react';

const TableRowArticle = (props: {
    id: string;
    key: string;
    col_article_nr: string;
    col_name: string;
    col_manufacturer: string;
    col_producttype: string;
}) => {
    const getUrl = (target: string) => {
        return '/backend/article/' + target + '/' + props.col_article_nr;
    };

    return (
        <tr key={props.id}>
            <td className="p-2 border border-gray-200">{props.col_article_nr}</td>
            <td className="p-2 border border-gray-200">{props.col_name}</td>
            <td className="p-2 border border-gray-200">{props.col_manufacturer}</td>
            <td className="p-2 border border-gray-200">{props.col_producttype}</td>
            <td className="p-2 border border-gray-200">
                <div className="grid grid-cols-2 gap-">
                    <div className="border-r border-gray-200 flex items-center justify-center">
                        <a href={getUrl('edit')}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-arrow-right-circle-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                        </a>
                    </div>
                    <div className="border-r border-gray-200 px-1">
                        <a href={getUrl('media')}>
                            <svg
                                width="30"
                                height="30"
                                viewBox="0 0 512 512"
                                id="Layer_1"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g>
                                    <path d="   M443,170.865v235H69v-235h112v-52.73c0-6.63,5.37-12,12-12h126c6.63,0,12,5.37,12,12v52.73H443z" />

                                    <circle cx="256" cy="287.229" fill="none" r="83.708" />

                                    <path
                                        d="   M256,323.938c-20.24,0-36.708-16.467-36.708-36.708S235.76,250.521,256,250.521c20.241,0,36.708,16.467,36.708,36.708   S276.241,323.938,256,323.938z"
                                        fill="none"
                                    />

                                    <line fill="none" x1="369" x2="411" y1="203.521" y2="203.521" />

                                    <rect fill="none" height="32" width="53" x="87" y="138.865" />

                                    <path
                                        d="   M443,170.865v235H69v-235h112v-52.73c0-6.63,5.37-12,12-12h126c6.63,0,12,5.37,12,12v52.73H443z"
                                        fill="#65A0D6"
                                    />

                                    <circle cx="256" cy="287.229" fill="#D9DCE1" r="83.708" />

                                    <path
                                        d="   M256,323.938c-20.24,0-36.708-16.467-36.708-36.708S235.76,250.521,256,250.521c20.241,0,36.708,16.467,36.708,36.708   S276.241,323.938,256,323.938z"
                                        fill="#3C74BA"
                                    />

                                    <line fill="none" x1="369" x2="411" y1="203.521" y2="203.521" />

                                    <rect fill="#3C74BA" height="32" width="53" x="87" y="138.865" />
                                </g>
                            </svg>
                        </a>
                    </div>
                    {/*<div className="border-r border-gray-200">&nbsp;</div>*/}
                    {/*<div>&nbsp;</div>*/}
                </div>
            </td>
        </tr>
    );
};

export default TableRowArticle;
