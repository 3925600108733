import React from 'react';

class InputTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputid: props.id,
            label: props.label,
            error: props.error,
            val: props.value,
        };
    }

    handleChangeText = (event) => {
        const text = event.target.value;
        this.props.onChange(this.props.id, text);
    };

    render() {
        return '' !== this.state.label ? (
            <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/4">
                    <label
                        className={
                            false === this.state.error && '' === this.state.val
                                ? 'block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                                : 'block text-red-500 font-bold md:text-right mb-1 md:mb-0 pr-4'
                        }
                        htmlFor="inline-full-name"
                    >
                        {this.state.label}
                    </label>
                </div>
                <div className="md:w-3/4">
                    <textarea
                        className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 ml-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 h-36"
                        id={this.state.inputid}
                        value={this.props.value}
                        onChange={this.handleChangeText}
                    />
                </div>
            </div>
        ) : (
            <div className="md:flex md:items-center mb-6">
                <div className="md:w-full">
                    <textarea
                        className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 ml-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 h-36"
                        id={this.state.inputid}
                        value={this.props.value}
                        onChange={this.handleChangeText}
                    />
                </div>
            </div>
        );
    }
}

export default InputTextArea;
