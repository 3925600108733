import axios from 'axios';
import { getUrl } from '../../../Website/Config';
import { fetchToken } from '../../../Website/Auth';

class CrudUtils {
    static token = fetchToken();
    static axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.token,
            'Access-Control-Allow-Origin': '*',
        },
    };
    static axiosConfigEmail = {
        headers: {
            'Content-Type': 'application/pdf',
            Authorization: 'Bearer ' + this.token,
        },
    };

    static axiosConfigFiles = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token,
            'Access-Control-Allow-Origin': '*',
        },
    };

    static postData = async (data: any, url: string) => {
        axios
            .post(getUrl() + url, data, this.axiosConfig)
            .then(function (response) {
                if (response.status === 200) {
                    console.log('Data saved for ' + response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    static postEmailData = async (data: any, url: string) => {
        axios
            .post(getUrl() + url, data, this.axiosConfig)
            .then(function (response) {
                if (response.status === 200) {
                    console.log(data);
                    // console.log(response.data);
                    // console.log('Data saved for ' + response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    static getData = async (url: string | number) => {
        try {
            // @ts-ignore
            const { data: response } = await axios.get(getUrl() + url);
            return response;
        } catch (error) {
            // @ts-ignore
            throw new Error(error);
        }
    };

    static createDocumentNumber = (type: string) => {
        const date = new Date();
        return (
            date.getFullYear() +
            '-' +
            this.getWeek(date) +
            '-' +
            date.getDate() +
            '-' +
            date.getHours() +
            date.getMinutes() +
            date.getSeconds() +
            '-' +
            type
        );
    };

    static getWeek(date: any) {
        date = new Date();

        // ISO week date weeks start on Monday, so correct the day number
        var nDay = (date.getDay() + 6) % 7;

        // ISO 8601 states that week 1 is the week with the first Thursday of that year
        // Set the target date to the Thursday in the target week
        date.setDate(date.getDate() - nDay + 3);

        // Store the millisecond value of the target date
        var n1stThursday = date.valueOf();

        // Set the target to the first Thursday of the year
        // First, set the target to January 1st
        date.setMonth(0, 1);

        // Not a Thursday? Correct the date to the next Thursday
        if (date.getDay() !== 4) {
            date.setMonth(0, 1 + ((4 - date.getDay() + 7) % 7));
        }

        // The week number is the number of weeks between the first Thursday of the year
        // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
        return 1 + Math.ceil((n1stThursday - date) / 604800000);
    }
}

export default CrudUtils;
