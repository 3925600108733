import React, { useState } from 'react';

import FrontendHeader from '../../Website/Frontend/FrontendHeader';
import FrontendFooter from '../../Website/Frontend/FrontendFooter';
import { useParams } from 'react-router-dom';
import CrudUtils from '../Backend/CRUD/CrudUtils';
import ListUtils from '../../Website/List/ListUtils';

const FrontendProductDetail = () => {
    let params = useParams();
    // let style = '';
    const [articleNumber] = useState(params.articlenumber);
    const [article, setArticle] = useState({
        article_id: '',
        name: '',
        number: '',
        producttype: '',
        manufacturer: '',
        description: '',
    });

    const [images, setImages] = useState([
        {
            filename: '',
            data: '',
            display: false,
        },
    ]);
    const [cnt, setCnt] = useState(0);
    const [, refreshState] = useState('');

    const setArticleData = async () => {
        try {
            return CrudUtils.getData('article/' + articleNumber);
        } catch (error) {
            console.error(error);
        }
    };

    setArticleData().then((articleData) => {
        if (0 === cnt) {
            setArticle(articleData['article']);
            setImages(articleData['files']);
            setCnt(1);
            console.log(articleData);
            localStorage.setItem('bannerText', JSON.stringify(articleData['article']['name']));
        }
    });

    function setVisibility(e: any) {
        for (let i = 0; i < images.length; i++) {
            if (images[i].filename === e) {
                setImages((prevState) => {
                    prevState[i].display = true;
                    return prevState;
                });
            } else
                setImages((prevState) => {
                    prevState[i].display = false;
                    return prevState;
                });
        }
        refreshState(ListUtils.makeid(10));
    }

    const articleDescription = { __html: article.description };

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<FrontendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-1 flex items-center justify-center text-white bg-[#3f888f]"
                ></section>

                {article.name !== '' ? (
                    <section className="p-8">
                        <div className="text-left">
                            <h1 className="text-2xl font-bold md:hidden text-[#3f888f]">{article.name}</h1>
                            <h2 className="text-sm text-left mb-4 text-gray-400">Art.-Nr.: {articleNumber}</h2>
                        </div>
                        <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-2 sm:gap-2 pb-5 w-full">
                            <div className="w-full">
                                <div className="w-full flex justify-center items-center shadow-lg">
                                    {images.map((image) =>
                                        image.display ? (
                                            <img
                                                key={ListUtils.makeid(10)}
                                                src={'data:image/png;base64,' + image.data}
                                                alt={image.filename}
                                                className="md:h-96 m-1 p-1 block"
                                            />
                                        ) : null,
                                    )}
                                </div>
                                <div className="grid grid-cols-3 sm:grid sm:grid-cols-6">
                                    {images.map((image) => (
                                        <div key={ListUtils.makeid(10)}>
                                            <img
                                                src={'data:image/png;base64,' + image.data}
                                                alt={image.filename}
                                                className="max-h-24 md:w-24 md:h-24 object-cover mr-1 mb-1 mt-1 p-1 cursor-pointer shadow-lg"
                                                onClick={() => setVisibility(image.filename)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="w-full p-2" dangerouslySetInnerHTML={articleDescription} />
                        </div>
                    </section>
                ) : (
                    <div className="w-full">
                        <h3 className="text-[#3f888f] ml-5 p-20">Bitte warten....</h3>
                    </div>
                )}

                {<FrontendFooter />}
            </div>
        </main>
    );
};

export default FrontendProductDetail;
