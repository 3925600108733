import React from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import NaviCrud from '../../../Website/Backend/NaviCrud';

const BackendOfferList = () => {
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold ml-5">Angebote</h1>
                        <div>
                            <NaviCrud target="offer" size="large" />
                        </div>
                    </div>
                </section>

                <section className="p-8">
                    <div className="p-5 bg-white">
                        <section>
                            <div className="pb-5">
                                <div className="w-full p-2">Hallo Welt</div>
                            </div>
                        </section>
                    </div>
                </section>

                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendOfferList;
