import ListItem from './ListItem';
import React from 'react';
import ShowDocumentPositions from '../../Content/Backend/CRUD/ShowDocumentPositions';
import ListItemSaleArticle from './ListItemSaleArticle';

class ListUtils {
    static prepareListDataMultiple = (listData: any[], dataType: any) => {
        const tmp: React.JSX.Element[] = [];
        listData.map((data) => {
            const tmpdata = (
                <ListItem
                    id={data['id']}
                    key={data['id']}
                    headlineCardHeader={data['headlineCardHeader']}
                    headline2={data['headline2']}
                    headline1={data['headline1']}
                    info1={data['info1']}
                    info2={data['info2']}
                    info3={data['info3']}
                    dataType={dataType}
                />
            );
            tmp.push(tmpdata);
        });
        return tmp;
    };

    static makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    static prepareListDataPositions = (data: any[]) => {
        const tmp: React.JSX.Element[] = [];
        data.map((data) => {
            const tmpdata = (
                <ShowDocumentPositions
                    id={data.id}
                    key={data.id}
                    amount={data.amount}
                    article={data.article}
                    price={data.price}
                />
            );
            tmp.push(tmpdata);
        });
        return tmp;
    };

    static prepareListItemSaleArticle = (requestData: any[], images: any[]) => {
        return requestData.map((data) => (
            <ListItemSaleArticle
                id={data['id']}
                key={data['id']}
                item_name={data['name']}
                item_number={data['number']}
                item_description={data['description']}
                item_imagename={data['filename']}
                item_image={this.getImages(data['filename'], images)}
            />
        ));
    };

    private static getImages = (_dataFileName: String, images: any[]) => {
        let imageData = '';
        images.map((image) => {
            if (_dataFileName === image['filename']) {
                imageData = image['data'];
            }
        });

        return imageData;
    };
}

export default ListUtils;
