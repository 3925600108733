import React, { useState } from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
// @ts-ignore
import { validate as isValidUUID } from 'uuid';
import CrudUtils from '../CRUD/CrudUtils';
import { useParams } from 'react-router-dom';
import BackendArticleEditForm from './BackendArticleEditForm';

const BackendArticleEdit = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const params = useParams();
    const [articleNumber] = useState(params.articlenumber);

    const [article, setArticle] = useState({
        article_id: '',
        name: '',
        number: '',
        producttype: '',
        manufacturer: '',
        description: '',
        gender: '',
        active: false,
        refinement: false,
        article_for_sale: false,
    });

    const setArticleData = async () => {
        try {
            return CrudUtils.getData('article/' + articleNumber);
        } catch (error) {
            console.error(error);
        }
    };

    setArticleData().then((articleData) => {
        if (articleData !== undefined && articleData['article'] !== null) {
            const articleDataFromDB = articleData['article'];
            if (article.article_id === '') {
                setArticle({
                    article_id: articleDataFromDB['id'],
                    name: null === articleDataFromDB['name'] ? '' : articleDataFromDB['name'],
                    // @ts-ignore
                    number: articleNumber,
                    producttype: null === articleDataFromDB['producttype'] ? '' : articleDataFromDB['producttype'],
                    manufacturer: null === articleDataFromDB['manufacturer'] ? '' : articleDataFromDB['manufacturer'],
                    description: null === articleDataFromDB['description'] ? '' : articleDataFromDB['description'],
                    gender: null === articleDataFromDB['gender'] ? '' : articleDataFromDB['gender'],
                    active: articleDataFromDB['active'],
                    refinement: articleDataFromDB['refinement'],
                    article_for_sale: articleDataFromDB['article_for_sale'],
                });
            }
        }
    });

    const handleFieldChange = (inputFieldId: any, inputFieldValue: any) => {
        setArticle({ ...article, [inputFieldId]: inputFieldValue });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        const articleData = {
            article_number: article.number,
            name: article.name,
            producttype: article.producttype,
            manufacturer: article.manufacturer,
            description: article.description,
            gender: article.gender,
            active: article.active,
            refinement: article.refinement,
            article_for_sale: article.article_for_sale,
        };

        axios
            .post(getUrl() + 'article/update', articleData, CrudUtils.axiosConfig)
            .then(function (response) {
                if (response.status === 201) {
                    setError(false);
                    setMessage('Artikel: ' + articleData.name + ' wurde erfolgreich angelegt.');
                } else {
                    setMessage('Es gab einen Fehler beim Anlegen des Artikels (Status ' + response.status + ').');
                    setError(true);
                }
            })
            .catch((error) => {
                let infoMessage = error.message;
                setMessage('Es gab einen Fehler beim Anlegen des Kunden: ' + infoMessage);
                setError(true);
            });
    };

    // @ts-ignore
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold ml-5">neuer Artikel</h1>
                        <div className="mr-4">{articleNumber}</div>
                    </div>
                </section>

                <section className="p-8">
                    <div className="p-5 bg-white">
                        <section>
                            <div className="pb-5">
                                <div className="w-full p-2">
                                    <section className="p-8">
                                        <div className="p-5 bg-white">
                                            <BackendArticleEditForm
                                                article={article}
                                                handleFieldChange={handleFieldChange}
                                                handleSubmit={handleSubmit}
                                            />
                                            {error && message !== '' ? (
                                                <p className="mt-4 text-center text-red-500 text-xl p-5">{message}</p>
                                            ) : (
                                                <p className="mt-4 text-center text-green-500 text-xl p-5">{message}</p>
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendArticleEdit;
