import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
import ListItemContact from '../../../Website/List/ListItemContact';

const BackendMessages = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const response = await axios.get(getUrl() + 'contact/new', axiosConfig);
                console.log(response);
                const requestData = response.data.data;
                const listData = requestData.map(
                    (data: {
                        id: React.Key | null | undefined;
                        remarks: any;
                        name: any;
                        email: any;
                        subject: any;
                        read: any;
                        created: any;
                    }) => (
                        <ListItemContact
                            id={data.id}
                            key={data.id}
                            remarks={data.remarks}
                            name={data.name}
                            email={data.email}
                            subject={data.subject}
                            read={data.read}
                            created={data.created}
                        />
                    ),
                );
                setMessages(listData);
            } catch (error) {
                console.error('Error fetching messages', error);
            }
        };

        fetchMessages();
    }, []);

    return <span>{messages}</span>;
};

export default BackendMessages;
