import React from 'react';

class FormUtils {
    static sanitizeInput = (input) => {
        const div = document.createElement('div');
        div.textContent = input;
        return div.innerHTML;
    };

    // Function to strip HTML tags, scripts, and SQL queries
    static stripInput = (input) => {
        // Regex to remove HTML tags
        input = input.replace(/<\/?[^>]+(>|$)/g, '');

        // Regex to remove SQL-like statements (basic example)
        input = input.replace(/(SELECT|INSERT|DELETE|UPDATE|CREATE|DROP|ALTER)\s/gi, '');

        // Regex to remove JavaScript
        input = input.replace(/<script.*?>.*?<\/script>/gi, '');

        return input;
    };

    static checkBoxChecked = (input) => {
        return input === true;
    };

    static inputFieldEmpty = (input) => {
        return input === true;
    };

    static isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    static isValidateFormData = (fields) => {
        let valid = true;
        fields.forEach((field) => {
            if (field === '') {
                valid = false;
            }
        });
        return valid;
    };

    static userInfoMessage = (message, type) => {
        let value = '';
        switch (type) {
            case 'danger':
                value = <p className="mt-4 text-center text-red-500 text-xl p-5">{message}</p>;
                break;
            case 'info':
                value = <p className="mt-4 text-center text-green-500 text-xl p-5">{message}</p>;
                break;
        }

        return value;
    };
}

export default FormUtils;
