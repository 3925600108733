import React from 'react';

const BackendFooter = () => {

    return (
        <section>
            <footer className="bg-[#3f888f] text-white text-center">
                <p className="pt-10 pb-10">&copy; 2024 Lagocki - Refinement, Textile & Print<br/>Alle Rechte vorbehalten
                </p>
            </footer>
        </section>
    );

}

export default BackendFooter;