import React from 'react';
// @ts-ignore
import Quality2Img from '../../Website/ImagesFiles/quality_2.png';
// @ts-ignore
import Service1Img from '../../Website/ImagesFiles/service_1.png';
// @ts-ignore
import Design1Img from '../../Website/ImagesFiles/design_1.png';
import FrontendHeader from '../../Website/Frontend/FrontendHeader';
import FrontendFooter from '../../Website/Frontend/FrontendFooter';

const FrontendFlex = () => {
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white">
                {<FrontendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-64 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="text-center">
                        <h1 className="text-4xl font-bold">Hochwertiger Flexdruck</h1>
                        <p className="mt-2 text-xl">
                            Bring Deine Designs zum Leben mit langlebigen und hochwertigen Flexdrucken.
                        </p>
                    </div>
                </section>

                <section className="p-8 mt-14">
                    <div className="mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8 ">
                        <div className="text-center border-2 rounded-xl p-5 shadow-xl">
                            <img className="mx-auto mb-4 max-h-48 object-cover" src={Quality2Img} alt="Hohe Qualität" />
                            <h2 className="text-2xl font-semibold">Hohe Qualität</h2>
                            <p className="mt-2">
                                Unsere Flexdrucke zeichnen sich durch ihre hohe Qualität und Langlebigkeit aus.
                            </p>
                        </div>
                        <div className="text-center border-2 rounded-xl p-5 shadow-xl">
                            <img
                                className="mx-auto mb-4 max-h-48 object-cover"
                                src={Design1Img}
                                alt="Individuelles Design"
                            />
                            <h2 className="text-2xl font-semibold">Individuelles Design</h2>
                            <p className="mt-2">
                                Gestalte Deine individuelle Designs, die genau Deinen Vorstellungen entsprechen.
                            </p>
                        </div>
                        <div className="text-center border-2 rounded-xl p-5 shadow-xl">
                            <img
                                className="mx-auto mb-4 max-h-48 object-cover"
                                src={Service1Img}
                                alt="Schneller Service"
                            />
                            <h2 className="text-2xl font-semibold">Schneller Service</h2>
                            <p className="mt-2">Profitiere von unserem schnellen und zuverlässigen Service.</p>
                        </div>
                    </div>
                </section>

                <section className="p-12 bg-gray-50">
                    <div className="container px-4 p-10">
                        <div className="text-center mb-20">
                            <h1 className="text-3xl font-bold">Bringe Deine Designs zum Strahlen mit Flexdruck!</h1>
                            <p className="mt-4">
                                Möchtest Du Deine kreativen Ideen zum Leben erwecken? Mit unserem hochwertigen Flexdruck
                                bieten wir Dir die perfekte Lösung für langlebige und lebendige Drucke auf Textilien.
                            </p>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div>
                                <h2 className="text-2xl font-semibold mb-4">Warum Flexdruck?</h2>
                                <ul className="list-disc list-inside">
                                    <li className="mb-4">
                                        <strong>Hervorragende Qualität:</strong>
                                        <p className="mt-1 ml-5">
                                            Unsere Flexdrucke sind nicht nur optisch ansprechend, sondern auch extrem
                                            langlebig. Sie behalten ihre Farben und Details auch nach vielen Wäschen.
                                        </p>
                                    </li>
                                    <li className="mb-4">
                                        <strong>Vielfältige Einsatzmöglichkeiten:</strong>
                                        <p className="mt-1 ml-5">
                                            Egal ob T-Shirts, Hoodies, Sportbekleidung oder Taschen – Flexdruck ist
                                            vielseitig einsetzbar und eignet sich hervorragend für individuelle Designs.
                                        </p>
                                    </li>
                                    <li className="mb-4">
                                        <strong>Individuelle Gestaltung:</strong>
                                        <p className="mt-1 ml-5">
                                            Gestalte Deine Textilien genau nach Deinen Vorstellungen. Flexdruck
                                            ermöglicht scharfe und klare Linien sowie leuchtende Farben, die garantiert
                                            auffallen.
                                        </p>
                                    </li>
                                    <li className="mb-4">
                                        <strong>Schnelle Umsetzung:</strong>
                                        <p className="mt-1 ml-5">
                                            Profitiere von unserem schnellen und zuverlässigen Service. Wir drucken
                                            Deine Designs in kürzester Zeit und liefern Dir perfekte Ergebnisse.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="text-2xl font-semibold mb-4">Unser Angebot für Dich:</h2>
                                <ul className="list-disc list-inside">
                                    <li className="mb-4">
                                        <strong>Attraktive Preise:</strong>
                                        <p className="mt-1 ml-5">
                                            Profitiere von unseren attraktiven Preisen ohne versteckte Kosten.
                                        </p>
                                    </li>
                                    <li className="mb-4">
                                        <strong>Persönlicher Service:</strong>
                                        <p className="mt-1 ml-5">
                                            Unser Team steht Dir von der Idee bis zur Umsetzung zur Seite. Wir beraten
                                            Dich gerne zu allen Fragen rund um den Flexdruck.
                                        </p>
                                    </li>
                                    <li className="mb-4">
                                        <strong>Textilien:</strong>
                                        <p className="mt-1 ml-5">
                                            Die passenden Textilien für Dein Projekt findest Du{' '}
                                            <a href="https://lagocki.printwear.promo" className="underline font-bold">
                                                hier
                                            </a>
                                            .
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="text-center mt-8 p-0">
                            <p>Mit unserem Flexdruck wird Dein Design zum Hingucker!</p>
                        </div>
                    </div>
                </section>

                {<FrontendFooter />}
            </div>
        </main>
    );
};

export default FrontendFlex;
