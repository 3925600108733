import React from 'react';
import FormUtils from '../Form/FormUtils';

const ListItemSaleArticle = (props: {
    id: string;
    key: string;
    item_name: string;
    item_description: string;
    item_image: string;
    item_imagename: string;
    item_number: string;
}) => {
    const imageUrl = 'data:image/png;base64,' + props.item_image;

    return (
        <div className="text-center border-2 rounded-xl p-5 shadow-xl">
            <a href={'/produkte/' + props.item_number}>
                <img className="mx-auto mb-4 max-h-48 object-cover" src={imageUrl} alt={props.item_imagename} />
                <h2 className="text-2xl font-semibold">{props.item_name}</h2>
                <p className="mt-2 truncate">{FormUtils.stripInput(props.item_description)}</p>
            </a>
        </div>
    );
};

export default ListItemSaleArticle;
