import React from 'react';
import DatabaseUtils from '../Database/DatabaseUtils';
import moment from 'moment';

class ListItemContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            remarks: props.remarks,
            name: props.name,
            email: props.email,
            subject: props.subject,
            read: props.read,
            created: moment(props.created).format('DD.MM.YYYY, H:mm') + ' Uhr',
            message: '',
        };
    }

    componentDidMount() {
        const btnRead = document.getElementById(`read_${this.state.id}`);
        btnRead.addEventListener('click', this.updateData);

        const btnShow = document.getElementById(`show_${this.state.id}`);
        btnShow.addEventListener('click', this.showData);
    }

    updateData = () => {
        const data = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            remarks: this.state.remarks,
            read: true,
            show: false,
        };

        DatabaseUtils.updateData(`contact/update/${this.state.id}`, data).then((result) => {
            if (true === result) {
                console.log('Data updated');
                const div = document.getElementById(`div_${this.state.id}`);
                div.remove();
            } else {
                this.setState({ message: 'Error updating data' });
            }
        });
    };

    showData = () => {
        const div = document.getElementById(`remarks_${this.state.id}`);
        if (window.getComputedStyle(div).display === 'none') {
            div.style.display = 'block';
            this.setState({ show: true });
        } else {
            div.style.display = 'none';
            this.setState({ show: false });
        }
    };

    render() {
        return (
            <div
                id={`div_${this.state.id}`}
                className="mb-6 border border-gray-300 rounded-lg pb-2"
                key={this.state.key}
            >
                <div>
                    <div className="text-blue-700 text-md mb-2 bg-gray-300 px-6 py-2 flex justify-between rounded-t-lg border">
                        <div className="text-gray-700 w-2/5">{this.state.subject}</div>
                        <div className="text-gray-700 text-xs text-right pt-2 px-2">vom {this.state.created}</div>
                        <div className="flex">
                            <button
                                id={`show_${this.state.id}`}
                                className="text-sm hover:text-cyan-500 bg-gray-200 rounded-t-lg rounded-r-lg rounded-b-lg rounded-l-lg w-14 text-center mr-3"
                                title="anzeigen"
                            >
                                {' '}
                                {true === this.state.show ? 'hide' : 'show'}
                            </button>
                            <button
                                id={`read_${this.state.id}`}
                                className="text-sm hover:text-cyan-500 bg-gray-200 px-0 rounded-t-lg rounded-r-lg rounded-b-lg rounded-l-lg w-8 text-center text-red-500"
                                title="gelesen"
                            >
                                x
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        <h3 className="text-md font-bold text-amber-950 pl-4 pr-1 pb-2">{this.state.email}</h3>|&nbsp;
                        <div className="text-sm font-bold text-amber-950 mt-0.5">{this.state.name}</div>
                    </div>
                    <div id={`remarks_${this.state.id}`} className="text-md text-gray-700 pl-4 pr-1 pb-2 hidden">
                        {this.state.remarks}
                    </div>
                    {'' !== this.state.message ? (
                        <div className="text-red-800 w-full pl-4 ">{this.state.message}</div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

export default ListItemContact;
