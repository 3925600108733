import React from 'react';

const TableRowAddress = (props: {
    id: string;
    key: string;
    col_customer_nr: string;
    col_email: string;
    col_name: string;
    col_street: string;
    col_zip_city: string;
}) => {
    const getUrl = () => {
        return '/backend/customer/edit/' + props.id;
    };

    return (
        <tr key={props.id}>
            <td className="p-2 border border-gray-200">{props.col_customer_nr}</td>
            <td className="p-2 border border-gray-200">{props.col_email}</td>
            <td className="p-2 border border-gray-200">{props.col_name}</td>
            <td className="p-2 border border-gray-200">{props.col_street}</td>
            <td className="p-2 border border-gray-200">{props.col_zip_city}</td>
            <td className="p-2 border border-gray-200">
                <div className="grid grid-cols-4 gap-">
                    <div className="border-r border-gray-200 flex items-center justify-center">
                        <a href={getUrl()}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-arrow-right-circle-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                            </svg>
                        </a>
                    </div>
                    <div className="border-r border-gray-200">&nbsp;</div>
                    <div className="border-r border-gray-200">&nbsp;</div>
                    <div>&nbsp;</div>
                </div>
            </td>
        </tr>
    );
};

export default TableRowAddress;
