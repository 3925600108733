import React, { useEffect, useState } from 'react';
import FrontendHeader from '../../Website/Frontend/FrontendHeader';
import FrontendFooter from '../../Website/Frontend/FrontendFooter';
import axios from 'axios';
import { getUrl } from '../../Website/Config';
import ListUtils from '../../Website/List/ListUtils';

const FrontendProducts = () => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchRows = async () => {
            try {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const listArticle = await axios.get(getUrl() + 'article/sale/list', axiosConfig);
                const listData = ListUtils.prepareListItemSaleArticle(
                    listArticle.data.articles,
                    listArticle.data.images,
                );
                // @ts-ignore
                setRows(listData);
            } catch (error) {
                console.error('Error fetching messages', error);
            }
        };
        fetchRows();
    }, []);

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<FrontendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-64 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="text-center">
                        <h1 className="text-4xl font-bold pb-4">Unsere Produkte</h1>
                        <h2 className="text-sm font-semibold mb-4 text-center">
                            eine Auswahl unserer Produkte und Dienstleistungen
                        </h2>
                    </div>
                </section>

                {rows.length !== 0 ? (
                    <section className="p-8 mt-14">
                        <div className="mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8 ">{rows}</div>
                    </section>
                ) : (
                    <div className="w-full">
                        <h3 className="text-[#3f888f] ml-5 p-20">Bitte warten....</h3>
                    </div>
                )}

                {<FrontendFooter />}
            </div>
        </main>
    );
};

export default FrontendProducts;
