import React from 'react';

const FromtendFooter = () => {

    return (
        <section>
            <footer className="bg-[#3f888f] text-white text-center">
                <p className="pt-10 pb-10">&copy; 2024 Lagocki - Refinement, Textile & Print<br/>Alle Rechte vorbehalten<br/>
                    <a href="/datenschutzerklaerung"
                       className="underline">Datenschutzerkl&auml;rung</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href="/impressum" className="underline">Impressum</a>
                </p>
            </footer>
        </section>
    );

}

export default FromtendFooter;