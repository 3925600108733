import React from 'react';

class ButtonBack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
        };
    }

    handleHistoryBack = () => {
        history.back();
    };

    render() {
        return (
            <div className="md:items-center">
                <div className="md:w-1/3"></div>
                <div className="md:w-2/3">
                    <button
                        className="shadow bg-blue-700 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white py-1 px-4 rounded items-end m-2"
                        type="button"
                        onClick={this.handleHistoryBack}
                    >
                        {this.state.label}
                    </button>
                </div>
            </div>
        );
    }
}

export default ButtonBack;
