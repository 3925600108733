import React from 'react';
import Button from '../../../Website/Form/Button';
// @ts-ignore
import { validate as isValidUUID } from 'uuid';
import InputText from '../../../Website/Form/InputText';
import InputTextArea from '../../../Website/Form/InputTextArea';
import InputCheckbox from '../../../Website/Form/InputCheckbox';
import InputDropdown from '../../../Website/Form/InputDropdown';
import ButtonBack from '../../../Website/Form/ButtonBack';

const BackendArticleEditForm = (props: any) => {
    const producttypesChoice = [
        { value: 'tshirt', label: 'T-Shirt' },
        { value: 'hoodie', label: 'Hoodie' },
        { value: 'bag', label: 'Tasche' },
        { value: 'cap', label: 'Caps' },
        { value: 'mug', label: 'Tassen' },
        { value: 'mask', label: 'Masken' },
        { value: 'jacket', label: 'Jacken' },
        { value: 'pants', label: 'Hosen' },
        { value: 'shorts', label: 'Shorts' },
        { value: 'socks', label: 'Socken' },
        { value: 'shoes', label: 'Schuhe' },
        { value: 'accessory', label: 'Accessoires' },
        { value: 'woodbox-mirror', label: 'Holzboxen & Spiegel' },
        { value: 'frame-mirror', label: 'Rahmen & Spiegel' },
        { value: 'woodbox', label: 'Holzboxen' },
        { value: 'lamp-acryl', label: 'Lampen' },
        { value: 'clock', label: 'Uhren' },
        { value: 'textile-finishing', label: 'Textilveredelung' },
    ];

    const genderChoice = [
        { value: 'woman', label: 'Damen' },
        { value: 'man', label: 'Herren' },
        { value: 'children', label: 'Kinder' },
        { value: 'neutral', label: 'Neutral' },
    ];

    return (
        <form id="new-article">
            <div className="pb-5">
                <div className="w-full p-2">
                    <InputText
                        id="name"
                        label="Name"
                        error=""
                        show={false}
                        onChange={props.handleFieldChange}
                        value={props.article.name}
                    />
                </div>
                <div className="w-full p-2">
                    <InputText
                        id="number"
                        label="Nummer"
                        error=""
                        show={false}
                        onChange={props.handleFieldChange}
                        value={props.article.number}
                    />
                </div>
                <div className="w-full p-2">
                    {'' === props.article.producttype ? (
                        <InputDropdown
                            id="producttype"
                            label="Produktart"
                            items={producttypesChoice}
                            onChange={props.handleFieldChange}
                            value={props.article.producttype}
                        />
                    ) : (
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/4">
                                <label
                                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                    htmlFor="inline-full-name"
                                >
                                    Produktart
                                </label>
                            </div>
                            <div className="md:w-3/4">{props.article.producttype}</div>
                        </div>
                    )}
                </div>
                <div className="w-full p-2">
                    {'' === props.article.producttype ? (
                        <InputDropdown
                            id="gender"
                            label="Geschlecht"
                            items={genderChoice}
                            onChange={props.handleFieldChange}
                            value={props.article.gender}
                        />
                    ) : (
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/4">
                                <label
                                    className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                    htmlFor="inline-full-name"
                                >
                                    Geschlecht
                                </label>
                            </div>
                            <div className="md:w-3/4">{props.article.gender}</div>
                        </div>
                    )}
                </div>
                <div className="w-full p-2">
                    <InputText
                        id="manufacturer"
                        label="Hersteller   "
                        error=""
                        show={false}
                        onChange={props.handleFieldChange}
                        value={props.article.manufacturer}
                    />
                </div>
                <div className="w-full p-2">
                    <InputTextArea
                        id="description"
                        label="Beschreibung"
                        error={false}
                        onChange={props.handleFieldChange}
                        value={props.article.description}
                    />
                </div>

                <div className="grid grid-cols-3 gap-3">
                    <div className="w-full p-2">
                        <InputCheckbox
                            id="active"
                            show={false}
                            error=""
                            label="Aktiv"
                            checked={props.article.active}
                            change=""
                            link=""
                            onChange={props.handleFieldChange}
                        />
                    </div>
                    <div className="w-full p-2">
                        <InputCheckbox
                            id="refinement"
                            show={false}
                            error=""
                            label="Veredelbar"
                            checked={props.article.refinement}
                            change=""
                            link=""
                            onChange={props.handleFieldChange}
                        />
                    </div>
                    <div className="w-full p-2">
                        <InputCheckbox
                            id="article_for_sale"
                            show={false}
                            error=""
                            label="Verkaufsartikel"
                            checked={props.article.article_for_sale}
                            change=""
                            link=""
                            onChange={props.handleFieldChange}
                        />
                    </div>
                </div>
                <div className="mb-4 mt-12 justify-end w-full flex flex-row">
                    <Button label="Speichern" onClick={props.handleSubmit} />
                    <ButtonBack label="Zurück" />
                </div>
            </div>
        </form>
    );
};

export default BackendArticleEditForm;
