import React, { useState } from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import CrudUtils from '../CRUD/CrudUtils';
import InputTextArea from '../../../Website/Form/InputTextArea';
import Button from '../../../Website/Form/Button';
import InputText from '../../../Website/Form/InputText';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import SetDocumentPositions from '../CRUD/SetDocumentPositions';
import FormUtils from '../../../Website/Form/FormUtils';
import ListUtils from '../../../Website/List/ListUtils';
// import BackendOfferRowShow from './BackendOfferRowShow';

const BackendOfferEdit = () => {
    const [message, setMessage] = useState('');
    const params = useParams();
    const [offerNumber] = useState(params.offernumber);
    const [offer, setOffer] = useState({
        offer_id: '',
        offer_number: '',
        offer_date: '',
        customer_name: '',
        customer_number: '',
        descriptionCustomer: '',
        internDescription: '',
        offerPositions: [
            {
                id: '',
                amount: '',
                article: '',
                price: '',
            },
        ],
    });
    const [offerPositionData, setOfferPositionData] = useState({
        offer_id: offer.offer_id,
        amount: '',
        article: '',
        price: '',
    });

    const setOfferData = async () => {
        try {
            return await CrudUtils.getData('offer/' + offerNumber);
        } catch (error) {
            console.error(error);
        }
    };

    setOfferData().then((offerData) => {
        if (offerData !== undefined) {
            const offerDataFromDB = offerData['offer'];
            if (offer.offer_id === '') {
                setOffer({
                    offer_id: offerDataFromDB['id'],
                    offer_number: offerDataFromDB['offer_number'],
                    offer_date: moment(offerDataFromDB['offer_date']).format('DD.MM.YYYY'),
                    customer_name: offerDataFromDB['forename'] + ' ' + offerDataFromDB['lastname'],
                    customer_number: offerDataFromDB['customer_number'],
                    descriptionCustomer: null !== offerDataFromDB['description'] ? offerDataFromDB['description'] : '',
                    internDescription:
                        null !== offerDataFromDB['intern_description'] ? offerDataFromDB['intern_description'] : '',
                    offerPositions: offerData['offer_position'].map((position: any) => {
                        return {
                            id: position.id,
                            amount: position.amount,
                            article: position.article,
                            price: position.price,
                        };
                    }),
                });
            }
        }
    });

    const handleFieldChangeOffer = (inputFieldId: any, inputFieldValue: any) => {
        // @ts-ignore
        setOffer({ ...offer, [inputFieldId]: inputFieldValue });
    };

    const handleFieldChangeOfferPosition = (inputFieldId: any, inputFieldValue: any) => {
        // @ts-ignore
        setOfferPositionData({ ...offerPositionData, [inputFieldId]: inputFieldValue });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        offerPositionData.offer_id = offer.offer_id;

        // @ts-ignore
        const response = await SetDocumentPositions.SetPositions(offerPositionData, 'offerposition/new');

        // @ts-ignore
        setMessage(FormUtils.userInfoMessage(response[0], response[1]));

        const rowcontainer = document.getElementById('offerPositionExisting');
        if (rowcontainer !== null) {
            let euro = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
            });
            // @ts-ignore
            const total = euro.format(offerPositionData.amount * offerPositionData.price);

            rowcontainer.insertAdjacentHTML(
                'afterend',
                '<div class="grid grid-cols-4 gap-4"><div class="mb-4 bg-gray-200 p-1 pl-4 rounded-xl w-2/4">' +
                    offerPositionData.amount +
                    '</div><div class="mb-4 bg-gray-200 p-1 pl-4 rounded-xl w-full">' +
                    offerPositionData.article +
                    '</div><div class="mb-4 bg-gray-200 p-1 pl-4 rounded-xl w-2/4">' +
                    // @ts-ignore
                    euro.format(offerPositionData.price) +
                    '</div><div class="mb-4 bg-gray-200 p-1 pl-4 rounded-xl w-2/4">' +
                    total +
                    '</div>' +
                    '</div>',
            );
        }
        setOfferPositionData({
            offer_id: offer.offer_id,
            amount: '',
            article: '',
            price: '',
        });
    };

    const updateOffer = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        const updateOfferData = {
            offer_id: offer.offer_id,
            description: offer.descriptionCustomer,
            intern_description: offer.internDescription,
        };

        try {
            await CrudUtils.postData(updateOfferData, 'offer/update');
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    // @ts-ignore
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section className="bg-cover bg-center h-24 flex items-center justify-between text-white bg-[#3f888f]">
                    <div className="w-full">
                        <h1 className="text-4xl font-bold ml-5">Angebot bearbeiten:</h1>
                        <h3 className="text-gray-200 ml-5">
                            Angebot: <strong className="text-amber-950">{offer.offer_number}</strong> für{' '}
                            <strong className="text-amber-950">{offer.customer_name}</strong> vom{' '}
                            <strong className="text-amber-950">{offer.offer_date}</strong>
                        </h3>
                    </div>
                    <div className="w-full">
                        <h1 className="text-4xl font-bold mr-5 text-right">Angeb:</h1>
                        <h3 className="text-gray-200  mr-5 text-right">
                            <strong className="text-amber-950"> &euro;</strong>
                        </h3>
                    </div>
                </section>

                <section className="p-8">
                    <div className="p-5 bg-white">
                        <div className="pb-5">
                            <div className="w-full p-2">
                                <form id="contact-form">
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="mb-1 pl-2">Informationen für den Kunden</div>
                                        <div className="mb-1 pl-2">interne Informationen</div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div>
                                            <InputTextArea
                                                id="descriptionCustomer"
                                                label=""
                                                error=""
                                                onChange={handleFieldChangeOffer}
                                                value={offer.descriptionCustomer}
                                            />
                                        </div>
                                        <div>
                                            <InputTextArea
                                                id="internDescription"
                                                label=""
                                                error=""
                                                onChange={handleFieldChangeOffer}
                                                value={offer.internDescription}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-1 mb-4 border-b border-gray-400">
                                        <div className="mb-4 items-end w-full content-end">
                                            <Button label="Angebot aktualisieren" onClick={updateOffer} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="mb-1 pl-2 text-gray-700">Anzahl:</div>
                                        <div className="mb-1 pl-2 text-gray-700">Artikel:</div>
                                        <div className="mb-1 pl-2 text-gray-700">Preis:</div>
                                        <div className="mb-1 pl-2 text-gray-700">Gesamt:</div>
                                    </div>
                                    <div id="offerPositionExisting">
                                        {ListUtils.prepareListDataPositions(offer.offerPositions)}
                                    </div>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="mb-4">
                                            <InputText
                                                id="amount"
                                                label=""
                                                error=""
                                                show={false}
                                                onChange={handleFieldChangeOfferPosition}
                                                value={offerPositionData.amount}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <InputText
                                                id="article"
                                                label=""
                                                error=""
                                                show={false}
                                                onChange={handleFieldChangeOfferPosition}
                                                value={offerPositionData.article}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <InputText
                                                id="price"
                                                label=""
                                                error=""
                                                show={false}
                                                onChange={handleFieldChangeOfferPosition}
                                                value={offerPositionData.price}
                                            />
                                        </div>
                                        <div className="mb-4">&nbsp;</div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-1">
                                        <div className="mb-4 w-full content-end">
                                            <Button label="Position speichern" onClick={handleSubmit} />
                                        </div>
                                    </div>
                                </form>
                                {message}
                            </div>
                        </div>
                    </div>
                </section>

                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendOfferEdit;
