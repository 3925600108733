import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RequireToken } from './Components/Website/Auth';
import Login from './Components/Website/Login';
import FrontendHome from './Components/Content/Frontend/FrontendHome';
import BackendHome from './Components/Content/Backend/BackendHome';
import FrontendFlex from './Components/Content/Frontend/FrontendFlex';
import FrontendLaser from './Components/Content/Frontend/FrontendLaser';
import FrontendContact from './Components/Content/Frontend/FrontendContact';
import FrontendDatenschutzhinweis from './Components/Content/Frontend/FrontendDatenschutzhinweis';
import FrontendImpressum from './Components/Content/Frontend/FrontendImpressum';
import BackendOfferList from './Components/Content/Backend/Offer/BackendOfferList';
import BackendCustomerStart from './Components/Content/Backend/Customer/BackendCustomerStart';
import BackendCustomerCreate from './Components/Content/Backend/Customer/BackendCustomerCreate';
import BackendCustomerEdit from './Components/Content/Backend/Customer/BackendCustomerEdit';
import BackendOfferEdit from './Components/Content/Backend/Offer/BackendOfferEdit';
import BackendOfferCreate from './Components/Content/Backend/Offer/BackendOfferCreate';
import BackendOfferMailTemplate from './Components/Content/Backend/Mails/BackendOfferMailTemplate';
import BackendArticleStart from './Components/Content/Backend/Article/BackendArticleStart';
import BackendArticleCreate from './Components/Content/Backend/Article/BackendArticleCreate';
import BackendArticleEdit from './Components/Content/Backend/Article/BackendArticleEdit';
import BackendArticleMedia from './Components/Content/Backend/Article/BackendArticleMedia';
import FrontendProducts from './Components/Content/Frontend/FrontendProducts';
import FrontendProductDetail from './Components/Content/Frontend/FrontendProductDetail';

const App = () => {
    return (
        <div className="bg-gray-200">
            {/*<a href="/">Home |</a>*/}
            {/*<a href="/backend"> Backend</a>*/}
            <Router>
                <Routes>
                    <Route path="/" element={<FrontendHome />} />
                    <Route path="/flexdruck" element={<FrontendFlex />} />
                    <Route path="/lasergravur" element={<FrontendLaser />} />
                    <Route path="/kontakt" element={<FrontendContact />} />
                    <Route path="/produkte" element={<FrontendProducts />} />
                    <Route path="/produkte/:articlenumber" element={<FrontendProductDetail />} />
                    <Route path="/datenschutzerklaerung" element={<FrontendDatenschutzhinweis />} />
                    <Route path="/impressum" element={<FrontendImpressum />} />
                    <Route
                        path="/backend"
                        element={
                            <RequireToken>
                                <BackendHome />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/article/list"
                        element={
                            <RequireToken>
                                <BackendArticleStart />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/article/create"
                        element={
                            <RequireToken>
                                <BackendArticleCreate />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/article/edit/:articlenumber"
                        element={
                            <RequireToken>
                                <BackendArticleEdit />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/article/media/:articlenumber"
                        element={
                            <RequireToken>
                                <BackendArticleMedia />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/offer/list"
                        element={
                            <RequireToken>
                                <BackendOfferList />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/offer/create/:customerid"
                        element={
                            <RequireToken>
                                <BackendOfferCreate />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/offer/edit/:offernumber"
                        element={
                            <RequireToken>
                                <BackendOfferEdit />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/offer/preview/:offernumber"
                        element={
                            <RequireToken>
                                <BackendOfferMailTemplate />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/customer/list"
                        element={
                            <RequireToken>
                                <BackendCustomerStart />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/customer/create"
                        element={
                            <RequireToken>
                                <BackendCustomerCreate />
                            </RequireToken>
                        }
                    />
                    <Route
                        path="/backend/customer/edit/:customerid"
                        element={
                            <RequireToken>
                                <BackendCustomerEdit />
                            </RequireToken>
                        }
                    />
                    {/*<Route path="/backend/article/new" element={*/}
                    {/*    <RequireToken>*/}
                    {/*        <BackendArticleNew/>*/}
                    {/*    </RequireToken>*/}
                    {/*}/>*/}
                    {/*<Route path="/backend/user/create" element={*/}
                    {/*    <RequireToken>*/}
                    {/*        <BackendUserCreate/>*/}
                    {/*    </RequireToken>*/}
                    {/*}/>*/}
                    {/*<Route path="/backend/user/create-address/:createduserid/:createduseremail" element={*/}
                    {/*    <RequireToken>*/}
                    {/*        <BackendUserAddress/>*/}
                    {/*    </RequireToken>*/}
                    {/*}/>*/}
                    <Route path="/login" element={<Login />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;
