// @ts-ignore
import React from 'react';

const ShowDocumentPositions = (props: { id: string; amount: any; article: string; price: any }) => {
    let euro = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
    const total = euro.format(props.amount * props.price);

    return (
        <div className="md:pt-1" key={props.id}>
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white">
                <div className="grid grid-cols-4 gap-4">
                    <div className="mb-2 bg-gray-200 p-1 pl-4 rounded-xl w-2/4">{props.amount}</div>
                    <div className="mb-2 bg-gray-200 p-1 pl-4 rounded-xl w-full">{props.article}</div>
                    <div className="mb-2 bg-gray-200 p-1 pl-4 rounded-xl w-2/4">{euro.format(props.price)}</div>
                    <div className="mb-2 bg-gray-200 p-1 pl-4 rounded-xl w-2/4">{total}</div>
                </div>
            </div>
        </div>
    );
};

export default ShowDocumentPositions;
