import React from 'react';
import FrontendHeader from "../../Website/Frontend/FrontendHeader";
import FrontendFooter from "../../Website/Frontend/FrontendFooter";

const FrontendImpressum = () => {

    return (
        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white'>
                {<FrontendHeader/>}
                <section id="showcase-laser"
                         className="bg-cover bg-center h-36 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold">Impressum</h1>
                    </div>
                </section>
                <section>
                    <div className="container mx-auto p-6 ">
                        <div className="mb-10 border-2 rounded-xl p-5">

                            <h2 className="text-2xl font-bold mb-4">Angaben gemäß § 5 TMG</h2>
                            <p>
                                <strong>Lagocki - Textile, Print & Refinement</strong><br/>
                                Auf der Horst 32<br/>
                                48308 Senden
                            </p>

                            <h2 className="text-2xl font-bold mt-8 mb-4">Vertreten durch</h2>
                            <p>Andreas Lagocki</p>

                            <h2 className="text-2xl font-bold mt-8 mb-4">Kontakt</h2>
                            <p>
                                Telefon: 02598 / 83 19 771<br/>
                                E-Mail: <a href="mailto:andreas@lagocki.de"
                                           className="text-blue-600 underline">andreas[at]lagocki.de</a>
                            </p>

                            <h2 className="text-2xl font-bold mt-8 mb-4">Streitschlichtung</h2>
                            <p>
                                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                                <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer"
                                   className="text-blue-600 underline">https://ec.europa.eu/consumers/odr</a>.<br/>
                                Unsere E-Mail-Adresse finden Sie oben im Impressum.
                            </p>
                            <p>
                                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen.
                            </p>

                            <h2 className="text-2xl font-bold mt-8 mb-4">Haftung für Inhalte</h2>
                            <p>
                                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                                nach
                                den allgemeinen Gesetzen verantwortlich.
                                Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                                übermittelte
                                oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
                                die
                                auf eine rechtswidrige Tätigkeit hinweisen.
                            </p>
                            <p>
                                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                                allgemeinen Gesetzen bleiben hiervon unberührt.
                                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                                konkreten
                                Rechtsverletzung möglich.
                                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                                umgehend
                                entfernen.
                            </p>

                            <h2 className="text-2xl font-bold mt-8 mb-4">Haftung für Links</h2>
                            <p>
                                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
                                Einfluss haben.
                                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                                Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
                                der
                                Seiten verantwortlich.
                                Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                                überprüft.
                                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                            </p>
                            <p>
                                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                            </p>

                            <h2 className="text-2xl font-bold mt-8 mb-4">Urheberrecht</h2>
                            <p>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                                dem
                                deutschen Urheberrecht.
                                Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                                bzw.
                                Erstellers.
                                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                                Gebrauch
                                gestattet.
                            </p>
                            <p>
                                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                                Urheberrechte Dritter beachtet.
                                Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                                Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
                                einen
                                entsprechenden Hinweis.
                                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                                entfernen.
                            </p>
                        </div>
                    </div>
                </section>
                {<FrontendFooter/>}
            </div>
        </main>


    );
}

export default FrontendImpressum;