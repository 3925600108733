import React from 'react';
import UserUtils from '../User/UserUtils';

const TopnaviFrontend = () => {
    const toggleMenu = () => {
        document.getElementById('nav-menu').classList.toggle('hidden');
    };

    const getNavi = () => {
        return (
            <div className="bg-gray-800 text-white text-center">
                <nav className="container mx-auto p-4 flex justify-between items-center">
                    <div className="hidden md:flex space-x-4">
                        <a href="/" className="hover:bg-gray-700 px-3 py-2 rounded">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 50 50"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"
                                ></path>
                            </svg>
                        </a>
                        <a href="/lasergravur" className="hover:bg-gray-700 px-3 py-2 rounded">
                            Lasergravur
                        </a>
                        <a href="/flexdruck" className="hover:bg-gray-700 px-3 py-2 rounded">
                            Textilveredelung
                        </a>
                        {/*<a href="/flexdruck" className="hover:bg-gray-700 px-3 py-2 rounded">Flexdruck</a>*/}
                        <a href="/kontakt" className="hover:bg-gray-700 px-3 py-2 rounded">
                            Kontakt
                        </a>
                        <a href="/produkte" className="hover:bg-gray-700 px-3 py-2 rounded">
                            Produkte
                        </a>
                    </div>
                    <div className="md:hidden">
                        <button onClick={toggleMenu} className="focus:outline-none">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div className="text-sm flex-wrap flex text-gray-500">
                        {UserUtils.getUser() !== null ? (
                            <a href="/backend" className="flex">
                                <div className="mr-2">
                                    {UserUtils.getUser()['forename'] + ' ' + UserUtils.getUser()['lastname']}
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                >
                                    <path d="M19 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h2c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2h2c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM17 20H7V4h10v16zM9 13h2v2H9v-2z" />
                                </svg>
                            </a>
                        ) : (
                            <a href="/login">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                >
                                    <path d="M19 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h2c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2h2c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM17 20H7V4h10v16zM9 13h2v2H9v-2z" />
                                </svg>
                            </a>
                        )}
                    </div>
                </nav>
                <div id="nav-menu" className="hidden md:hidden bg-gray-700 text-white">
                    <a href="/" className="block px-4 py-2 hover:bg-gray-600">
                        Home
                    </a>
                    <a href="/lasergravur" className="block px-4 py-2 hover:bg-gray-600">
                        Lasergravur
                    </a>
                    <a href="/flexdruck" className="block px-4 py-2 hover:bg-gray-600">
                        Textilveredelung
                    </a>
                    <a href="/kontakt" className="block px-4 py-2 hover:bg-gray-600">
                        Kontakt
                    </a>
                    <a href="/produkte" className="block px-4 py-2 hover:bg-gray-600">
                        Produkte
                    </a>
                </div>
            </div>
        );
    };

    return <div>{getNavi()}</div>;
};

export default TopnaviFrontend;
