import { useLocation, Navigate } from 'react-router-dom';

export const setToken = (token) => {
    localStorage.setItem('hpknzAfSPmfI8AAI', token);
    localStorage.setItem('setToken', Date.now());
};

export const fetchToken = () => {
    return localStorage.getItem('hpknzAfSPmfI8AAI') || null;
};

export function RequireToken({ children }) {
    const location = useLocation();
    const auth = fetchToken();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
}

export function isTokenValid() {
    let valid = true;
    // const location = useLocation();
    let timeSetToken = localStorage.getItem('setToken') || null;

    // wenn 10 min nichts passiert ist, dann wird der Token gelöscht (7200000 Millisekunden = 2 Stunden)
    let currentTime = Date.now();
    if (!timeSetToken || currentTime - timeSetToken > 600000) {
        localStorage.removeItem('hpknzAfSPmfI8AAI');
        valid = false;
    } else {
        let token = fetchToken();
        if (null !== token) {
            setToken(token);
        }
    }

    return valid;
}

export const removeToken = () => {
    localStorage.removeItem('hpknzAfSPmfI8AAI');
    localStorage.removeItem('setToken');
};
