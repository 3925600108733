import React from 'react';

// @ts-ignore
import add from '../ImagesFiles/plus.png';

const NaviCrud = (props: { target: string; size: string }) => {
    const getTarget = () => {
        // @ts-ignore
        return '/backend/' + props.target + '/create';
    };

    const getImage = () => {
        if ('large' === props.size) {
            return <img className="mx-auto mb-4 max-h-48 object-cover" src={add} alt="Create" />;
        }
        return <img className="mx-auto mb-4 max-h-8 object-cover" src={add} alt="Create" />;
    };

    return (
        <div className="text-center w-14 mt-4 mr-5">
            <a href={getTarget()}>{getImage()}</a>
        </div>
    );
};

export default NaviCrud;
