import React from 'react';
import { isTokenValid } from '../Auth';
import TopnaviBackendAdmin from './TopnaviBackendAdmin';
import { Navigate, useLocation } from 'react-router-dom';

const BackendHeader = () => {
    const location = useLocation();

    return isTokenValid() ? (
        <section>
            <div className="text-sm text-gray-700 text-right border-t-gray-180 border-b-gray-180 border-b-2">
                {<TopnaviBackendAdmin />}
            </div>
        </section>
    ) : (
        <Navigate to="/login" state={{ from: location }} />
    );
};

export default BackendHeader;
