import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
// @ts-ignore
// import add from '../../../Website/ImagesFiles/plus.png';
import TableUtils from '../../../Website/Table/TableUtils';

const BackendCustomerList = () => {
    const [rows, setRows] = useState([]);

    // const clearCacheData = () => {
    //     caches.keys().then((names) => {
    //         names.forEach((name) => {
    //             caches.delete(name);
    //         });
    //     });
    //     alert('Complete Cache Cleared');
    // };

    useEffect(() => {
        const fetchRows = async () => {
            try {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                const listCustomers = await axios.get(getUrl() + 'customer/list', axiosConfig);
                const listData = TableUtils.prepareTableRowDataAddress(listCustomers.data);
                setRows(listData);
            } catch (error) {
                console.error('Error fetching messages', error);
            }
        };
        fetchRows();
    }, []);

    // @ts-ignore
    return (
        <section className="p-8">
            {/*<div>*/}
            {/*    <button onClick={() => clearCacheData()}>Clear Cache Data</button>*/}
            {/*</div>*/}
            <div className="p-5 bg-white">
                <section>
                    <div className="pb-5">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="w-1/6 border border-gray-400">Nummer</th>
                                    <th className="w-1/6 border border-gray-400">email</th>
                                    <th className="w-1/6 border border-gray-400">Name</th>
                                    <th className="w-1/6 border border-gray-400">Strasse</th>
                                    <th className="w-1/6 border border-gray-400">Ort</th>
                                    <th className="w-1/6 border border-gray-400"></th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </table>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default BackendCustomerList;
