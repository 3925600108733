import React, { useState } from 'react';
import Button from '../../../Website/Form/Button';
import FormUtils from '../../../Website/Form/FormUtils';
// @ts-ignore
// import { validate as isValidUUID } from 'uuid';
import InputText from '../../../Website/Form/InputText';
import InputDropdown from '../../../Website/Form/InputDropdown';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
import CrudUtils from '../CRUD/CrudUtils';

const BackendCustomerAddressCreate = (props: { customer: string; email: string }) => {
    const [message, setMessageAddress] = useState('');
    const [error, setError] = useState(false);
    const [customerId] = useState(props.customer);
    const [email] = useState(props.email);
    const [address, setStateAddress] = useState({
        salutation: '',
        forename: '',
        lastname: '',
        street: '',
        number: '',
        zip: '',
        city: '',
        phone: '',
    });

    const salutationChoice = [
        { value: 'mr', label: 'Herr' },
        { value: 'mrs', label: 'Frau' },
        { value: 'div', label: 'Divers' },
    ];

    const handleFieldChangeAddress = (inputFieldId: any, inputFieldValue: any) => {
        setStateAddress({ ...address, [inputFieldId]: inputFieldValue });
    };

    const handleAddressSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        const fields = [address.lastname, address.street, address.number, address.zip, address.city];

        if (!FormUtils.isValidateFormData(fields)) {
            setError(true);
            setMessageAddress('Die Adresse konnte nicht gespeichert werden. Bitte alle Felder ausfüllen.');
            return;
        }

        const addressData = {
            salutation: address.salutation,
            forename: address.forename,
            lastname: address.lastname,
            additional: '',
            street: address.street,
            number: address.number,
            city: address.city,
            zip: parseInt(address.zip),
            country: 'DE',
            phone: address.phone,
            customer_id: customerId,
        };

        axios
            .post(getUrl() + 'customer/address/new', addressData, CrudUtils.axiosConfig)
            .then(function (response) {
                console.log(response);
                if (response.status === 200 || response.status === 201) {
                    setStateAddress({
                        phone: '',
                        salutation: '',
                        forename: '',
                        lastname: '',
                        street: '',
                        number: '',
                        zip: '',
                        city: '',
                    });
                    setMessageAddress('Adresse wurde gespeichert.');
                } else {
                    setMessageAddress(
                        'Es gab einen Fehler beim Anlegen der Kunden Adresse (Status ' + response.status + ').',
                    );
                    setError(true);
                }
            })
            .catch((error) => {
                console.log(error);
                let infoMessage = error.message;
                setMessageAddress('Es gab einen Fehler beim Anlegen der Kunden Adresse: ' + infoMessage);
                setError(true);
            });
    };

    return (
        <section className="p-8">
            <div className="p-5 bg-white">
                <section>
                    <div className="pb-5">
                        <div className="w-full text-right pr-5">
                            <div className="text-gray-400">Adresse hinzufügen</div>
                            <h1 className="text-xl font-bold">{email}</h1>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="pb-5">
                        <hr />
                    </div>
                </section>
                <section>
                    <form id="new-article">
                        <div className="pb-5">
                            <div className="w-full p-2">
                                <InputDropdown
                                    id="salutation"
                                    label="Anrede"
                                    items={salutationChoice}
                                    onChange={handleFieldChangeAddress}
                                    value={address.salutation}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="forename"
                                    label="Vorname"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.forename}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="lastname"
                                    label="Nachname"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.lastname}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="street"
                                    label="Straße"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.street}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="number"
                                    label="Hausnummer"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.number}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="zip"
                                    label="Postleitzahl"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.zip}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="city"
                                    label="Ort"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.city}
                                />
                            </div>
                            <div className="w-full p-2">
                                <InputText
                                    id="phone"
                                    label="Telefon"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChangeAddress}
                                    value={address.phone}
                                />
                            </div>
                            <div className="mb-4 justify-end w-full flex">
                                <a
                                    href="/backend/customer"
                                    className="shadow bg-gray-300 hover:bg-gray-400 focus:shadow-outline focus:outline-none text-white py-1 px-4 rounded mr-5"
                                >
                                    Abbrechen
                                </a>
                                <Button label="Speichern" onClick={handleAddressSubmit} />
                            </div>
                        </div>
                    </form>
                    {error && message !== '' ? (
                        <p className="mt-4 text-center text-red-500 text-xl p-5">{message}</p>
                    ) : (
                        <p className="mt-4 text-center text-green-500 text-xl p-5">{message}</p>
                    )}
                </section>
            </div>
        </section>
    );
};

export default BackendCustomerAddressCreate;
