import React from 'react';
import BackendHeader from "../../Website/Backend/BackendHeader";
import BackendFooter from "../../Website/Backend/BackendFooter";
import BackendMessages from "./Messages/BackendMessages";


const BackendHome = () => {

    // const user = UserUtils.getUser();

    return (


        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white border-2'>

                {<BackendHeader/>}

                <section id="showcase-laser"
                         className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold pb-4">Dashboard</h1>
                    </div>
                </section>

                <section className="p-8">
                    <div className="p-5 bg-white">
                        <section>
                            <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-2 sm:gap-2 pb-5">
                                <div className="w-full p-2">
                                    <h2 className="text-2xl font-semibold mb-10">Nachrichten</h2>
                                    {<BackendMessages/>}
                                </div>
                                <div className="w-full p-2">
                                    &nbsp;
                                </div>
                            </div>
                        </section>
                    </div>
                </section>

                {<BackendFooter/>}

            </div>
        </main>

    )
        ;

}

export default BackendHome;