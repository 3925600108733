import React from 'react';
// @ts-ignore
import VisitenkarteImg from '../../Website/ImagesFiles/handy_visitenkarte_2.png';
// @ts-ignore
import SpiegelBoxImg from '../../Website/ImagesFiles/spiegel_box_2.png';
// @ts-ignore
import UhrenImg from '../../Website/ImagesFiles/uhren_2.png';
// @ts-ignore
import UntersetzerImg from '../../Website/ImagesFiles/untersetzer_2.png';
// @ts-ignore
import CapImg from '../../Website/ImagesFiles/cap_black_front.png';
// @ts-ignore
import TanktopImg from '../../Website/ImagesFiles/tanktop_black_front.png';
// @ts-ignore
import HoodieImg from '../../Website/ImagesFiles/hoodie_white_back.png';
// @ts-ignore
import TShirtImg from '../../Website/ImagesFiles/t-shirt_black_back.png';
import FrontendHeader from '../../Website/Frontend/FrontendHeader';
import FrontendFooter from '../../Website/Frontend/FrontendFooter';
import Service1Img from '../../Website/ImagesFiles/service_1.png';
import Design1Img from '../../Website/ImagesFiles/design_1.png';
import Quality2Img from '../../Website/ImagesFiles/quality_2.png';
import bannerFlex from '../../Website/ImagesFiles/banner-flexdruck-1.png';
import bannerLaser from '../../Website/ImagesFiles/banner-laser.png';

const FrontendHome = () => {
    // @ts-ignore
    // @ts-ignore
    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white">
                {<FrontendHeader />}
                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-96 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="text-center">
                        <h1 className="text-4xl font-bold">Veredelung, Textilien & Druck</h1>
                        <h2 className="lg:text-4xl lg:font-bold lg:mb-4 invisible lg:visible">
                            Professionelle Lasergravur & Textilveredelung ds
                        </h2>
                        <p className="text-lg md:text-2xl mb-8">
                            Präzision und Kreativität – maßgeschneiderte Lösungen für Ihre Projekte
                        </p>
                        <a
                            href="/kontakt"
                            className="bg-yellow-500 hover:bg-yellow-600 text-white py-3 px-6 rounded-lg text-lg"
                        >
                            Jetzt Anfragen
                        </a>
                    </div>
                </section>

                {/* Leistungen */}
                <section id="services" className="py-16">
                    <div className="container mx-auto p-6 ">
                        <div className="mb-10 border-2 rounded-xl p-5">
                            <div className="container mx-auto text-center">
                                <h3 className="text-3xl font-bold mb-12">Unsere Leistungen</h3>

                                <div className="grid md:grid-cols-2 gap-12">
                                    {/* Lasergravur & Laserschneiden */}
                                    <div className="bg-gray-100 p-8 shadow-md">
                                        <img
                                            className="w-auto inset-0 object-cover object-center mt-4 mb-8"
                                            src={bannerLaser}
                                            alt=""
                                        />
                                        <h4 className="text-2xl font-bold mb-4">Lasergravur & Laserschneiden</h4>
                                        <p className="mb-4">
                                            Wir bieten präzise Lasergravuren und -schnitte auf einer Vielzahl von
                                            Materialien, darunter Holz, Metall, Acryl und Glas. Ob für individuelle
                                            Geschenke oder Firmenschilder – wir realisieren Deine Ideen mit höchster
                                            Genauigkeit.
                                        </p>
                                        <ul className="list-disc list-inside text-left">
                                            <li>Gravur auf Holz, Glas, Metall und mehr</li>
                                            <li>Exakte Laserschnitte für individuelle Designs</li>
                                            <li>Personalisierte Produkte für private und geschäftliche Kunden</li>
                                        </ul>
                                        <p className="mb-4 mt-12">
                                            <a
                                                href="/lasergravur"
                                                className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-5 lg:py-3 lg:px-6 rounded-lg"
                                            >
                                                weitere Infos
                                            </a>
                                        </p>
                                    </div>

                                    {/* Textilveredelung mit Flexdruck */}
                                    <div className="bg-gray-100 p-8 shadow-md">
                                        <img
                                            className="w-auto inset-0 object-cover object-center mt-4 mb-8"
                                            src={bannerFlex}
                                            alt=""
                                        />
                                        <h4 className="text-2xl font-bold mb-4">Textilveredelung mit Flexdruck</h4>
                                        <p className="mb-4">
                                            Unsere Textilveredelung mit Flexdruck bietet langlebige und hochqualitative
                                            Drucke auf Textilien. Von personalisierten T-Shirts bis hin zu
                                            Firmenbekleidung – wir setzen Deine Vorstellungen in die Realität um.
                                        </p>
                                        <ul className="list-disc list-inside text-left">
                                            <li>Individuelle Designs und Logos auf Textilien</li>
                                            <li>Hochwertige Materialien für Langlebigkeit</li>
                                            <li>Ideal für Arbeitskleidung, Vereine und Events</li>
                                        </ul>
                                        <p className="mb-4 mt-12">
                                            <a
                                                href="/flexdruck"
                                                className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-5 lg:py-3 lg:px-6 rounded-lg"
                                            >
                                                weitere Infos
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Warum uns wählen */}
                <section>
                    <div className="container mx-auto p-6">
                        <div className="mb-10 border-2 rounded-xl p-5 bg-white">
                            <div className="container mx-auto text-center">
                                <h3 className="text-3xl font-bold mb-12">Warum uns wählen?</h3>
                                <div className="grid md:grid-cols-3 gap-12">
                                    <div className="text-center border-2 rounded-xl p-5 shadow-xl">
                                        <img
                                            className="mx-auto mb-4 max-h-48 object-cover"
                                            src={Quality2Img}
                                            alt="Hohe
                                            Qualität"
                                        />
                                        <h2 className="text-2xl font-semibold">Präzision</h2>
                                        <p className="mt-2">Modernste Technik für detailgenaue Ergebnisse</p>
                                    </div>
                                    <div className="text-center border-2 rounded-xl p-5 shadow-xl">
                                        <img
                                            className="mx-auto mb-4 max-h-48 object-cover"
                                            src={Design1Img}
                                            alt="Individuelles Design"
                                        />
                                        <h2 className="text-2xl font-semibold">Kreativität</h2>
                                        <p className="mt-2">
                                            Wir arbeiten eng mit Ihnen zusammen, um Ihre Vorstellungen perfekt
                                            umzusetzen
                                        </p>
                                    </div>
                                    <div className="text-center border-2 rounded-xl p-5 shadow-xl">
                                        <img
                                            className="mx-auto mb-4 max-h-48 object-cover"
                                            src={Service1Img}
                                            alt="Schneller Service"
                                        />
                                        <h2 className="text-2xl font-semibold">Kundenzufriedenheit</h2>
                                        <p className="mt-2">
                                            Wir arbeiten eng mit Ihnen zusammen, um Ihre Vorstellungen perfekt
                                            umzusetzen
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="container mx-auto text-center mt-12">
                                <div className="container mx-auto text-center">
                                    <p className="mb-6">
                                        Wir sind ein kleines, familiengeführtes Unternehmen mit Leidenschaft für
                                        Lasergravur und Textilveredelung. Unser Ziel ist es, hochwertige und
                                        individuelle Produkte zu schaffen, die Ihre Vorstellungen übertreffen. Mit
                                        modernster Technik und einem Auge für Details setzen wir Ihre Projekte
                                        professionell um.
                                    </p>
                                    <h3 className="text-3xl font-bold mb-12 mt-16">Kontaktieren Sie uns!</h3>
                                    <p className="mb-6">
                                        Hast Du Fragen oder möchtest ein Projekt besprechen? Kontaktiere uns gerne!
                                    </p>
                                    <a
                                        href="/kontakt"
                                        className="bg-yellow-500 hover:bg-yellow-600 text-white py-3 px-6 rounded-lg text-lg"
                                    >
                                        Lerne uns kennen
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section>*/}
                {/*    <div className="container mx-auto p-6 ">*/}
                {/*        <div className="mb-10 border-2 rounded-xl p-5">*/}
                {/*            <h2 className="text-xl font-semibold mb-2">Gravur und Schnitt mit Laser</h2>*/}
                {/*            <p className="mb-4">*/}
                {/*                Unsere Lasergravur- und Laserschnittdienste ermöglichen präzise Gravuren und Schnitte*/}
                {/*                auf einer Vielzahl von Materialien wie Holz, Acryl, Glas und Metall. Ideal für*/}
                {/*                personalisierte Geschenke, Dekorationsartikel und mehr.*/}
                {/*            </p>*/}
                {/*            <section>*/}
                {/*                <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-4 sm:gap-4 pb-5">*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={VisitenkarteImg} alt="Handyhülle und Visitenkarte" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={SpiegelBoxImg} alt="Spiegelbox" className="h72" />*/}
                {/*                    </div>*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={UhrenImg} alt="Uhren" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={UntersetzerImg} alt="Untersetzer" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </section>*/}
                {/*        </div>*/}

                {/*        <div className="mb-8 border-2 rounded-xl p-5">*/}
                {/*            <h2 className="text-xl font-semibold mb-2">Textilien</h2>*/}
                {/*            <p className="mb-4">*/}
                {/*                Wir bieten Dir eine langlebige und vielseitige Methode zur Veredelung Deiner Textilien.*/}
                {/*                Ob T-Shirts, Hoodies oder Taschen – wir realisieren Deine individuellen Wünsche:*/}
                {/*            </p>*/}
                {/*            <section>*/}
                {/*                <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-4 sm:gap-4 pb-5">*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={CapImg} alt="Cap" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={HoodieImg} alt="Top und Shirt" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={TanktopImg} alt="Shirt" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">*/}
                {/*                        <img src={TShirtImg} alt="Hoodie" className="h-72" />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </section>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {<FrontendFooter />}
            </div>
        </main>
    );
};

export default FrontendHome;
