import React, { useState } from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
import Button from '../../../Website/Form/Button';
import FormUtils from '../../../Website/Form/FormUtils';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
import InputEmail from '../../../Website/Form/InputEmail';
// @ts-ignore
import { validate as isValidUUID } from 'uuid';
import BackendCustomerAddressCreate from './BackendCustomerAddressCreate';
import CrudUtils from '../CRUD/CrudUtils';

const BackendCustomerCreate = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [newAddress, setNewAddress] = useState('hide');
    const [state, setState] = useState({
        email: '',
        newCustomer: '',
    });

    const handleFieldChange = (inputFieldId: any, inputFieldValue: any) => {
        setState({ ...state, [inputFieldId]: inputFieldValue });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        if (!FormUtils.isEmail(state.email)) {
            setError(true);
            setMessage('Der Kunde konnte nicht angelegt werden. Die Email Adresse ist nicht gültig.');
            return;
        }

        const customerData = {
            email: state.email,
        };

        axios
            .post(getUrl() + 'customer/new', customerData, CrudUtils.axiosConfig)
            .then(function (response) {
                if (response.status === 200) {
                    if (isValidUUID(response.data.result)) {
                        setState({
                            email: state.email,
                            newCustomer: response.data.result,
                        });
                        setNewAddress('show');
                    } else if (response.data.result === 'exists') {
                        setError(true);
                        setMessage('Kunde existiert bereits.');
                    } else {
                        setError(true);
                        setMessage(
                            'Es gab einen Fehler beim Anlegen des Kunden: ' +
                                response.data.result +
                                ' UUID ist ungültig.',
                        );
                    }
                } else {
                    setMessage('Es gab einen Fehler beim Anlegen des Kunden (Status ' + response.status + ').');
                    setError(true);
                }
            })
            .catch((error) => {
                let infoMessage = error.message;
                setMessage('Es gab einen Fehler beim Anlegen des Kunden: ' + infoMessage);
                setError(true);
            });
    };

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold ml-5">neuer Kunde:</h1>
                        <div>&nbsp;</div>
                    </div>
                </section>

                {'show' === newAddress ? (
                    <BackendCustomerAddressCreate customer={state.newCustomer} email={state.email} />
                ) : (
                    <section className="p-8">
                        <div className="p-5 bg-white">
                            <section>
                                <div className="pb-5">
                                    <div className="w-full p-2">
                                        <section className="p-8">
                                            <div className="p-5 bg-white">
                                                <form id="new-article">
                                                    <div className="pb-5">
                                                        <div className="w-full p-2">
                                                            <InputEmail
                                                                id="email"
                                                                label="Email Adresse"
                                                                error={error}
                                                                show={true}
                                                                valid={FormUtils.isEmail(state.email)}
                                                                onChange={handleFieldChange}
                                                                value={state.email}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="mb-4 justify-end w-full flex">
                                                            <Button label="Speichern" onClick={handleSubmit} />
                                                        </div>
                                                    </div>
                                                </form>
                                                {error && message !== '' ? (
                                                    <p className="mt-4 text-center text-red-500 text-xl p-5">
                                                        {message}
                                                    </p>
                                                ) : (
                                                    <p className="mt-4 text-center text-green-500 text-xl p-5">
                                                        {message}
                                                    </p>
                                                )}
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                )}
                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendCustomerCreate;
