import React from 'react';
import BannerStart from '../ImagesFiles/header_backend_3.png';
import BannerFlex from '../../Website/ImagesFiles/banner-flexdruck-1.png';
import BannerLaser from '../../Website/ImagesFiles/banner-laser.png';
import BannerContact from '../../Website/ImagesFiles/banner_kontakt.png';
import BannerProducts from '../../Website/ImagesFiles/banner_produkte.png';
import TopnaviFrontend from './TopnaviFrontend';
import { useLocation } from 'react-router-dom';

const FromtendHeader = () => {
    let banner;
    let bannerText;
    const location = useLocation();
    const pathname = location.pathname.slice(1);

    const getBanner = () => {
        if (pathname.match('produkte/')) {
            banner = BannerProducts;
            bannerText = JSON.parse(localStorage.getItem('bannerText'));
        } else {
            switch (pathname) {
                case 'flexdruck':
                    banner = BannerFlex;
                    break;
                case 'lasergravur':
                    banner = BannerLaser;
                    break;
                case 'kontakt':
                    banner = BannerContact;
                    break;
                case 'produkte':
                    banner = BannerProducts;
                    break;
                default:
                    banner = BannerStart;
            }
            localStorage.setItem('bannerText', null);
            bannerText = null;
        }

        return (
            <div className="font-semibold tracking-tight text-center text-gray-700 md:pt-0 pt-5 relative z-0 flex">
                {bannerText !== null ? (
                    <div className="absolute inset-y-0 left-5 z-10 w-1/3 hidden md:block">
                        <div className="flex h-full items-center justify-center text-4xl text-[#3f888f]">
                            {bannerText}
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <picture>
                    <img className="w-auto inset-0 object-cover object-center" src={banner} alt="" />
                </picture>
            </div>
        );
    };

    return (
        <section>
            {getBanner()}
            <div className="text-sm text-gray-700 text-right border-t-gray-180 border-b-gray-180 border-b-2">
                {<TopnaviFrontend />}
            </div>
        </section>
    );
};

export default FromtendHeader;
