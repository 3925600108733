import React, { useState } from 'react';
import BackendHeader from '../../../Website/Backend/BackendHeader';
import BackendFooter from '../../../Website/Backend/BackendFooter';
// @ts-ignore
import { validate as isValidUUID } from 'uuid';
import Button from '../../../Website/Form/Button';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getUrl } from '../../../Website/Config';
import CrudUtils from '../CRUD/CrudUtils';
import ButtonBack from '../../../Website/Form/ButtonBack';

const BackendArticleMedia = () => {
    const params = useParams();
    const [articleNumber] = useState(params.articlenumber);

    const [files, setFiles] = useState<FileList | null>(null);
    const [status, setStatus] = useState<'initial' | 'uploading' | 'success' | 'fail'>('initial');

    const [mediaInformation, setMediaInformation] = useState([]);
    const [imageFromServer, setImageFromServer] = useState([]);
    const [cnt, setCnt] = useState(0);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setStatus('initial');
            setFiles(e.target.files);
        }
    };

    const setMediaData = async () => {
        try {
            return CrudUtils.getData('media/' + articleNumber);
        } catch (error) {
            console.error(error);
        }
    };

    setMediaData().then((mediaData) => {
        if (0 === cnt) {
            console.log(mediaData);
            setMediaInformation(mediaData['data']);
            setImageFromServer(mediaData['files']);
            setCnt(1);
        }
    });
    // setTimeout(() => {
    //     console.log(media);
    // }, 1000);

    const handleUpload = async () => {
        if (files) {
            setStatus('uploading');

            const formData = new FormData();
            for (const file of [...files]) {
                formData.append('files', file);
            }

            try {
                axios
                    .post(getUrl() + 'media/upload/' + articleNumber, formData, CrudUtils.axiosConfigFiles)
                    .then(function (response) {
                        if (response.status === 201) {
                            setStatus('success');
                        } else {
                            setStatus('fail');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setStatus('fail');
                    });
            } catch (error) {
                console.error(error);
                setStatus('fail');
            }
        }
    };

    const Result = ({ status }: { status: string }) => {
        if (status === 'success') {
            return <p className="text-green-500">✅ File uploaded successfully!</p>;
        } else if (status === 'fail') {
            return <p className="text-red-500">❌ File upload failed!</p>;
        } else if (status === 'uploading') {
            return <p>⏳ Uploading selected file...</p>;
        } else {
            return null;
        }
    };

    const ShowImage = ({ medium }: { medium: any }) => {
        let imageResult = null;
        imageFromServer.map((image: any) => {
            if (image.filename === medium.filename) {
                // const imageUrl = URL.createObjectURL(new Blob(image.data, { type: 'image/jpeg' }));
                const imageUrl = 'data:image/png;base64,' + image.data;
                imageResult = (
                    <div className="w-24">
                        <img src={imageUrl} alt={medium.filename} />
                        <p className="text-xs mt-2 border-t border-gray-400">{medium.filename}</p>
                    </div>
                );
            }
        });

        return imageResult;
    };

    return (
        <main className="md:pt-1 pb-20">
            <div className="flex justify-center max-w-7xl flex-col mx-auto bg-white border-2">
                {<BackendHeader />}

                <section
                    id="showcase-laser"
                    className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]"
                >
                    <div className="flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold ml-5">Artikel Medien:</h1>
                        <div className="mr-4">&nbsp;</div>
                    </div>
                </section>

                <section className="p-8">
                    <div className="px-5 bg-white">
                        <section>
                            <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-2 sm:gap-2 pb-1">
                                <div className="w-full px-2">
                                    <h2 className="text-1xl text-gray-500 font-semibold mb-10">
                                        Vorhande Medien f&uuml;r {articleNumber}
                                    </h2>
                                </div>
                            </div>
                            <div className="border-b-2">
                                <div className="w-full flex flex-row">
                                    {mediaInformation &&
                                        mediaInformation.map((medium: any) => (
                                            <div key={medium.id} className="pb-5 border border-gray-200 m-1">
                                                <div className="w-full px-2">
                                                    <ShowImage medium={medium} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="px-8">
                    <div className="px-5 bg-white">
                        <section>
                            <div className="pb-5">
                                <div className="w-full px-2">
                                    <section className="px-8">
                                        <div className="px-5 bg-white">
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/4">
                                                    &nbsp;
                                                    <label
                                                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                        htmlFor="inline-full-name"
                                                    >
                                                        Medien
                                                    </label>
                                                </div>
                                                <div className="md:w-3/4 mt-5">
                                                    <input id="file" type="file" multiple onChange={handleFileChange} />
                                                </div>
                                            </div>

                                            <div className="input-group"></div>
                                        </div>
                                    </section>
                                    <section className="px-8">
                                        <div className="px-5 bg-white">
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/4">
                                                    &nbsp;
                                                    {files && (
                                                        <label
                                                            className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                                                            htmlFor="inline-full-name"
                                                        >
                                                            Ausgew&auml;hlte Dateien
                                                        </label>
                                                    )}
                                                </div>

                                                <div className="md:w-3/4">
                                                    {files &&
                                                        [...files].map((file, index) => (
                                                            <section key={file.name} className="my-5">
                                                                <span className="text-gray-400 font-bold">
                                                                    Datei #{index + 1}, Details:
                                                                </span>
                                                                <ul>
                                                                    <li>Name: {file.name}</li>
                                                                    <li>Type: {file.type}</li>
                                                                    <li>Size: {file.size} bytes</li>
                                                                </ul>
                                                            </section>
                                                        ))}

                                                    <Result status={status} />
                                                </div>
                                            </div>

                                            <div className="input-group"></div>
                                        </div>
                                    </section>

                                    <section className="px-8">
                                        <div className="mb-4 mt-12 justify-end w-full flex flex-row">
                                            {files && (
                                                <Button
                                                    label={
                                                        files.length > 1 ? 'Dateien hochladen' : 'eine Datei hochladen'
                                                    }
                                                    onClick={handleUpload}
                                                />
                                            )}
                                            <ButtonBack label="Zurück" />
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                {<BackendFooter />}
            </div>
        </main>
    );
};

export default BackendArticleMedia;
